.custom-select {
    height: 40px; /* Adjust height as needed */
    font-size: 14px; /* Adjust font size */
    padding: 5px 10px; /* Add padding for better spacing */
    max-width: 100%; /* Ensure it is responsive */
    overflow: hidden; /* Hide overflow */
  }
  
  .custom-select option {
    font-size: 14px; /* Adjust option font size */
  }
  
