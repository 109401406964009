.homeHero {
    position: relative;
    height: 300vh;
    
  }
  .homeTopSticky {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
  }
  .homeTopSolar {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .sunSection {
    position: absolute;
    top: -10%;
    left: -5%;
    width: 100%;
    align-items: start;
    display: flex;
    align-items: start;
    justify-content: center;
  }
  .sunSection > img {
    width: 35%;
  }
  
  .solarSection {
    position: absolute;
    top: 25%;
    width: 100%;
    align-items: start;
    justify-content: center;
  }
  .solarSection > img {
    width: 50%;
  }
  .grassSection {
    width: 100%;
    height: 40vh;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .grassSection > img {
    width: 100%;
    height: 100%;
  }
  .topTextSection {
    position: absolute;
    width: 100%;
    top: 20%;
  }

  @media (max-width: 992px) {
    .sunSection {
      position: absolute;
      top: -10%;
      left: -5%;
      width: 100%;
      align-items: start;
      display: flex;
      align-items: start;
      justify-content: center;
    }
    .sunSection > img {
      width: 50%;
    }
  
    .solarSection {
      position: absolute;
      top: 25%;
      width: 100%;
      align-items: start;
      justify-content: center;
    }
    .solarSection > img {
      width: 70%;
    }
    .grassSection {
      width: 100%;
      height: 40vh;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .grassSection > img {
      width: 100%;
      height: 100%;
    }
    .topTextSection {
      position: absolute;
      width: 100%;
      top: 15%;
    }
  
    .wcc1.active {
      bottom: 5%;
      left: 2%;
      transition: 1s;
    }
    .wcc2.active {
      bottom: 50%;
      left: 5%;
      transition: 1s;
    
    }
    .wcc3.active {
      bottom: 10%;
      left: 50%;
      transition: 1s;
    
    }
    .wcc4.active {
      bottom: 60%;
      left: 50%;
      transition: 1s; 
    }
  
    .homeRdnilText {
      color: white;
      font-weight: 800;
      font-size: 80px;
      word-spacing: 40px !important;
    }
  }
  
  @media (max-width: 768px) {
    .sunSection {
      position: absolute;
      top: -10%;
      left: -5%;
      width: 100%;
      align-items: start;
      display: flex;
      align-items: start;
      justify-content: center;
    }
    .sunSection > img {
      width: 70%;
    }
  
    .solarSection {
      position: absolute;
      top: 25%;
      width: 100%;
      align-items: start;
      justify-content: center;
    }
    .solarSection > img {
      width: 90%;
    }
    .grassSection {
      width: 100%;
      height: 40vh;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .grassSection > img {
      width: 100%;
      height: 100%;
    }
    .topTextSection {
      position: absolute;
      width: 100%;
      top: 15%;
    }
  
    .whyChooseCart {
      width: 90%;
      text-align: start;
  background: linear-gradient(45deg, #fff3ee, #fdc8ad, #fd9f70);
      position: absolute;
      bottom: -400px;
      left: -400px;
      transition: 0.5s;
    }
    .wcc1.active {
      bottom: 3%;
      left: 5%;
      transition: 1s;
    }
    .wcc2.active {
      bottom: 28%;
      left: 5%;
      transition: 1s;
    
    }
    .wcc3.active {
      bottom: 52%;
      left: 5%;
      transition: 1s;
    
    }
    .wcc4.active {
      bottom: 75%;
      left: 5%;
      transition: 1s; 
    }
    .homeRdnilText {
      color: white;
      font-weight: 800;
      font-size: 80px;
      word-spacing: 20px !important;
    }
  }
  
  @media (max-width: 576px) {
    .sunSection {
      position: absolute;
      top: 5%;
      left: -5%;
      width: 100%;
      align-items: start;
      display: flex;
      align-items: start;
      justify-content: center;
    }
    .sunSection > img {
      width: 100%;
    }
  
    .solarSection {
      position: absolute;
      top: 40%;
      left: -15%;
      width: 100%;
      align-items: start;
      justify-content: center;
    }
    .solarSection > img {
      width: 130%;
    }
    .grassSection {
      width: 100%;
      height: 50vh;
      position: absolute;
      bottom: 0;
      left: -20%;
    }
    .grassSection > img {
      width: 120%;
      height: 100%;
    }
    .topTextSection {
      position: absolute;
      width: 100%;
      top: 30%;
    }
    .homeRdnilText {
      color: white;
      font-weight: 800;
      font-size: 50px;
      word-spacing: 20px !important;
    }
  }