.card-btn :hover {
  background: linear-gradient(to right, #ff5e43, #fb992e);
}
.active-btn {
  background: linear-gradient(to right, #ff5e43, #fb992e);
}
.card-btn {
  width: 280px !important;
}
.custom-padding {
  padding-left: 10px;
}

@media (max-width: 576px) {
  .mainCard {
    height: 900px;
  }

  .content {
    display: flex;
    flex-direction: column;
  }
  /* .main-content{
    bottom: 10px !important;
  } */
}
@media (max-width: 425px) {
  .content {
    margin-bottom: 1px !important;
  }
  .main-content {
    top: 538px !important;
  }

  .card-btn {
    width: 250px !important;
  }
}
@media (min-width: 768px) {
  .title {
    margin-top: 10px !important;
    font-size: 4rem !important; /* Adjust as needed */
  }
  .main-content {
    top: 150px !important;
  }

  .desc1,
  .desc2 {
    font-size: 1.55rem !important; /* Adjust as needed */
  }
}

@media (max-width: 768px) {
  .title {
    margin-top: 10px !important;
    font-size: 2rem !important; /* Adjust as needed */
  }
  .main-content {
    top: 290px !important;
  }
  .desc1,
  .desc2 {
    font-size: 1.2rem !important; /* Adjust as needed */
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 2rem !important; /* Adjust as needed */
  }
  .desc1,
  .desc2 {
    font-size: 1.15rem !important; /* Adjust as needed */
  }
}

@media (max-width: 1025px) {
  button.card-btn {
    font-size: 1.3rem !important;
  }
  .content {
    margin-bottom: 34px;
  }
}
@media (max-width: 769px) {
  .content {
    margin-bottom: 4px;
  }
}
