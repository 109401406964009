* {
  font-family: "Darker Grotesque", sans-serif;
}

.main-container {
  background: linear-gradient(135deg, #fff8f4, #ffd1ba);
  
  padding: 10px 0;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Inquiry form styling with decreased width */
.inquiry-form {
  max-width: 500px;
  width: 100%;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Title styling */
.inquiry-form h2 {
  font-weight: 700;

  color: #333;
  margin-bottom: 25px;
  text-align: center;
  background: linear-gradient(to right, #ff5e43, #fb992e);
  -webkit-background-clip: text;
  color: transparent;
}

/* Label styling */
.inquiry-form .form-label {
  font-weight: 600;
  color: #333;

  margin-bottom: 8px;
}

/* Input and select styling */
.inquiry-form .form-control,
.inquiry-form .form-select {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 12px;

  color: #333;
  transition: all 0.3s ease;
}
.inquiry-form .form-control:focus,
.inquiry-form .form-select:focus {
  border-color: #ff5e43;
  box-shadow: 0 0 6px rgba(255, 94, 67, 0.4);
}

.inquiry-form .submit-btn {
  width: 100%;
  font-weight: bold;
  border-radius: 8px;
  padding: 12px;
  background-color: #ff5e43;
  color: #fff;

  transition: background-color 0.3s ease;
}
.inquiry-form .submit-btn:hover {
  background-color: #fd8e17;
}

/* Modal styling */
.modal-content {
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-weight: bold;
  color: #ff5e43;
}

.close {
  font-size: 1.5rem;
  color: #ff5e43;
  transition: color 0.3s;
}
.close:hover {
  color: #fb992e;
}
.modal-btn {
  background-color: red !important;
  color: #fff !important;
}
