.cards {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.image {
  transition: transform 0.6s ease, filter 0.6s ease;
}

.cards:hover .image {
  transform: scale(1.1);
  filter: grayscale(100%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: -20px;
  right: -40px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  background-color: transparent;
  border: none;
  font-size: 100px;
  color: white;
  cursor: pointer;
}

.left {
  left: -30px;
}

.right {
  right: -30px;
}

