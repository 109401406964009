* {
  font-family: "Darker Grotesque", sans-serif;
}

@media (max-width: 576px) {
  .main {
    height: 1200px !important;
  }

  .name {
    flex-direction: column !important;
  }

  .contact {
    margin-top: 10px;
    font-size: 3rem !important;
  }
}
@media (min-width: 577px) and (max-width: 769px) {
  .main {
    height: 700px !important;
  }
  /* .name {
    flex-direction: column !important;
  } */
  .contact {
    margin-top: 10px;
    font-size: 3rem !important;
  }
}

@media (min-width: 780px) and (max-width: 1200px) {
  .main {
    height: 700px !important;
  }
  /* .name {
    flex-direction: column !important;
  } */
  .contact {
    margin-top: 10px;
    font-size: 3rem !important;
  }
}
