/* ::-webkit-scrollbar {
  display: none;
} */

/* font  */

* {
  font-family: "Darker Grotesque", sans-serif;
}

/* setion1 */
.layer-image {
  width: 100vw;
  height: auto;
  object-fit: contain;
  margin-top: -600px;
  overflow: hidden;
}

/* Solar image base styles */
.solar-image {
  width: 100vw;
  height: auto;
  margin-bottom: 0;
}

/* Small screens (up to 768px) */
@media (min-width: 424px) and (max-width: 769px) {
  .layer-image {
    width: 100vw;
    margin-top: -20px !important;
  }

  .solar-image {
    width: 100vw;
    margin-bottom: 75px;
  }

  .main-heading {
    font-size: 2rem;
  }
}

/* Extra small screens (up to 576px) */
@media (max-width: 376px) {
  /* .layer-image {
    width: 100vw;
    margin-top: -550px; 
  } */

  .solar-image {
    width: 100vw;
  }

  .main-heading {
    font-size: 1.5rem;
  }
}

/* Medium screens (576px to 992px) */
@media (min-width: 576px) and (max-width: 992px) {
  .solar-image {
    width: 100vw;
    margin-bottom: 0;
  }

  .main-heading {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .light {
    margin-top: 140px !important;
  }
}

/* Large screens (992px to 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
  .solar-image {
    width: 90vw;
    margin-bottom: 0;
  }

  .main-heading {
    font-size: 3rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .layer-image {
    margin-top: -257px;
  }
  .light {
    margin-top: 50px;
  }
}

/* Extra large screens (min-width: 1200px) */
@media (min-width: 1200px) {
  .solar-image {
    width: 90vw;
    margin-bottom: 0;
  }

  .main-heading {
    font-size: 3.5rem;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .layer-image {
    margin-top: 129px !important;
  }
}
@media (min-width: 321px) and (max-width: 374px) {
  .layer-image {
    margin-top: 187px !important;
  }
}
@media (max-width: 320px) {
  .layer-image {
    margin-top: 212px !important;
  }
  .light {
    margin-top: 200px !important;
    width: 40vw;
  }
}

/* for Navbar */

.header-transition {
  transition: opacity 1s ease, transform 0.9s ease;
}

.header-visible {
  opacity: 1;
  transform: translateY(0);
}

.header-hidden {
  opacity: 0;
  transform: translateY(-100%);
}

#boxmodals {
  gap: 3rem;
}
@media (max-width: 768px) {
  #boxmodals {
    top: 11px !important;
    flex-direction: column !important;
    gap: 0.5rem !important;
  }
  .eachbox {
    transform: none !important;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  #boxmodals {
    top: 285px !important;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    place-content: center !important;
  }
}
@media (max-width: 768px) {
  #boxmodals {
    top: 122px !important;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    place-content: center !important;
    gap: 25px !important;
  }
  .why-us {
    margin-bottom: 28.5rem !important;
  }
}
/* 425 px first */
@media (max-width: 425px) {
  #boxmodals {
    top: 167px !important;
    height: auto !important;
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    /* place-content: top !important; */
    gap: 7px !important;
  }
  @media (max-width: 425px) {
    .why-us {
      margin-bottom: 39.5rem !important;
    }
    .eachbox {
      height: 115px !important;
    }

  }
}
@media (max-width: 375px) {
  #boxmodals {
    /* top: 74px !important; */
    height: auto !important;
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    /* place-content: top !important; */
    gap: 7px !important;
  }
  @media (max-width: 375px) {
    .why-us {
      margin-bottom: 29.5rem !important;
    }
    .eachbox {
      height: 115px !important;
    }

  }
}

/* @media (max-width: 768px) {
  .why-text {
    top: 30rem !important;
  }
} */
@media only screen and (max-width: 321px) {
  #boxmodals {
    top: 60px !important;
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    place-content: center !important;
    gap: 7px !important;
  }
  .why-us {
    margin-bottom: 31.5rem !important;
  }
  .eachbox {
    height: 115px !important;
  }
}

/* section 5  */

/* For screens up to 769px */
@media (max-width: 768px) {
  .residential-text {
    font-size: 5rem !important;
    margin-bottom: 37px !important;
  }

  .industrial-text,
  .solar-text {
    font-size: 5rem !important;
    margin-bottom: 44px !important;
  }
}

/* For screens up to 426px */
@media (max-width: 426px) {
  .residential-text {
    font-size: 3rem !important;
    margin-bottom: 48px !important;
    /* bottom: 0% !important; */
  }

  .industrial-text{
    font-size: 3rem !important;
    margin-bottom: 58px !important;
  }
  .solar-text {
    font-size: 3.5rem !important;
    margin-bottom: 52px !important;
  }
}

/* For screens up to 320px */
@media (max-width: 321px) {
  .residential-text {
    font-size: 2.5rem !important;
    margin-bottom: 54px !important;
  }

  .industrial-text {
    font-size: 2.5rem !important;
    margin-bottom: 59px !important;
  }

  .solar-text {
    font-size: 2.5rem !important;
    margin-bottom: 58px !important;
  }
}

/* secton 8 box-design  */
.cut-box {
  clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 85% 100%, 0% 100%);
}

.padding-none {
  padding: 0 !important;
  margin: 0 !important;
}

.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.box {
  transition: background-color 0.3s ease;
}

.box:hover {
  background-color: #78dcf8;
  opacity: 0.6;
}

/* Section 7 Our Partners */
.marquee {
  display: flex;
  animation: marquee 40s linear infinite;
  gap: 100px;
  cursor: pointer;
}
.partner-logo {
  height: 100px;
  width: auto;
  object-fit: contain;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee:hover {
  animation-play-state: paused;
}

/* .partner-logo {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.partner-logo:hover {
  filter: grayscale(0%);
} */
