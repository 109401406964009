/* .kw-options-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    gap: 20px;
  }
   */
@media (max-width: 576px) {
  .kw-options-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .boxes {
    min-height: 1915px !important;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 0.55rem !important;
    display: flex; /* Flexbox इस्तेमाल करें */
    justify-content: center; /* टेक्स्ट को सेंटर में लाएं */
    align-items: center; /* वर्टिकल सेंटर करें */
    gap: 5px; /* नंबर और टेक्स्ट के बीच थोड़ी जगह */
    flex-wrap: nowrap; /* टेक्स्ट को एक लाइन में रखें */
  }
}
