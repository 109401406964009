@media (min-width: 768px) {
  
  .image1 {
    margin-left: -190px;
  }
  .image2 {
    margin-left: 130px;
  }
}
@media (max-width: 576px) {
  .row {
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  .main-box {
    height: 690px !important;
  }
  .img-section {
    height: 74% !important;
  }
  .heading-section {
    padding-top: 50px !important;
  }
  .table-image {
    /* display: flex; */

    /* width: 100% !important; */
  }
}
