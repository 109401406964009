.icon {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.logo {
  height: 10vh;
}
.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* display: none; */

  background: linear-gradient(45deg, #fff8f4, #feb088);

  clip-path: circle(0% at 100% 0%);
  transition: clip-path 1s ease-in-out;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-menu.open {
  display: fixed;
  clip-path: circle(150% at 100% 0%);
}

.menu-item {
  color: #ff823c;
  font-size: 2rem;
  margin: 15px 0;
}

.home {
  margin-right: 200px;
  margin-top: -30px;
  height: 10vh;
}
.icon {
  position: absolute;

  top: 22px; /* Adjusts the space from the top */
  right: 45px; /* Adjusts the space from the right */
  z-index: 1; /* Ensures the icon appears on top */
  cursor: pointer; /* Adds a pointer cursor on hover */
}

/* .navbar { */
/* background: rgba(255, 255, 255, 0); */
/* border-radius: 16px;
  box-shadow: 0 0px 3px rgba(243, 231, 231, 0.1);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.1px);
  border: none; */
/* } */

.navbar {
  background: rgba(255, 255, 255, 0.02);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@media (max-width: 425px) {
  .btn {
    display: none !important;
  }
}
@media (min-width: 425px) {
  .btn-menu {
    display: none !important;
  }
}
